
import { defineComponent, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { useMsalAuthentication } from '@/composition-api/useMsalAuthentication';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '@/store/auth/activedirectory';
import { useIsAuthenticated } from '@/composition-api/useIsAuthenticated';
import { useMsal } from '@/composition-api/useMsal';
import LoginContainer from '@/components/LoginContainer.vue';

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const msal = useMsal();
    const msalauth = useMsalAuthentication(InteractionType.Redirect, loginRequest);
    const auth = useIsAuthenticated();
    const datainstore = store.getters.isLoggedIn;
    if (auth.value && datainstore) {
      router.push({ name: 'Index' });
    }
    if (!auth.value || !datainstore) {
      const unwatch = watch(
        [auth],
        () => {
          if (auth.value && !datainstore) {
            msalauth.instance
              .acquireTokenSilent({ ...loginRequest, account: msal.accounts.value[0] })
              .then((token) => {
                store.dispatch('login', { token: token.accessToken }).then(() => {
                  unwatch();
                  store.dispatch('addNotification', { type: 'sucess', message: 'Willkommen!' });
                  router.push({ name: 'Index' });
                });
              })
              .catch((e) => {
                console.error(e);
              });
          }
        },
        { immediate: true }
      );
    }
  },
  components: { LoginContainer },
});
